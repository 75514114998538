import { useState } from "react";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { slugify } from "@utils/slugify";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { Select } from "@czechtv/form";
import classNames from "classnames";
import {
  faqCategoryHeadline,
  faqSelect,
  faqSubCategoryHeadline,
  faqTopTags,
  faqTopTagsList,
  faqTxtContent,
  bedgeActive,
  bedge,
} from "@components/core/PageTypes/DocumentsPage/DocumentsPage.css";

const prepareCategories = (categories: any | any[]): any[] => {
  const preparedCategories: any[] = [];
  if (categories && categories?.length > 0) {
    categories.map((category: any) => {
      preparedCategories.push(category.value);
    });
  }
  return preparedCategories;
};

export const DocumentsPage = ({ page, allPages }: any) => {
  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const categories = prepareCategories(page.categories);

  return (
    <DefaultLayout page={page} allPages={allPages}>
      <>
        {/** navigace kategorii */}
        {categories?.length > 1 ? (
          <div className={classNames(faqTopTags)}>
            <ul className={classNames(faqTopTagsList)}>
              {categories.map((category: any, index: number) => (
                <li key={index}>
                  <a
                    className={
                      activeCategoryIndex == index ? classNames(bedgeActive) : classNames(bedge)
                    }
                    href={`#${slugify(category.headline)}`}
                    onClick={() => setActiveCategoryIndex(index)}
                  >
                    {category.headline}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** vypis kategorii */}
        {categories.length > 0 && (
          <div>
            {categories.map((category: any, index: number) => (
              <Category key={index} category={category} />
            ))}
          </div>
        )}
      </>
    </DefaultLayout>
  );
};

const Category = ({ category = undefined }: { category: any }) => {
  if (category === undefined) return null;
  return (
    <div id={slugify(category.headline)}>
      <h2 className={classNames(faqCategoryHeadline)}>{category.headline}</h2>
      {category.description && (
        <div className={classNames(faqTxtContent)}>
          <HTMLBlock HTML={category.description} />
        </div>
      )}

      {/** dokumenty */}
      {category.items?.length > 0 && <AttachedDocumentsBlock documents={category.items} />}

      {/** subkategorie */}
      <Subcategories subcategories={category.categories} />
    </div>
  );
};

const Subcategories = ({ subcategories = undefined }: { subcategories: any | any[] }) => {
  const [activeSubcategoryId, setActiveSubcategoryId] = useState<string>(
    subcategories?.length > 0 ? subcategories[0].headline : ""
  );
  if (subcategories === undefined || subcategories?.length == 0) return null;

  const subcategoriesOptions = subcategories.map((subcategory: any) => {
    return { id: slugify(subcategory.headline), text: subcategory.headline };
  });

  return (
    <div>
      {/** prepinani subkategorii */}
      {subcategories?.length > 1 && (
        <div className={faqSelect}>
          <strong>Vyberte: </strong>
          <div>
            <Select
              selectedOption={activeSubcategoryId}
              options={subcategoriesOptions}
              onChange={(value: string) => setActiveSubcategoryId(value)}
              placeholder={""}
              name={""}
            />
          </div>
        </div>
      )}

      {/** vypis subkategorii */}
      {subcategories?.length > 0 &&
        subcategories.map((subcategory: any, index: number) => (
          <Subcategory
            key={index}
            subcategory={subcategory}
            active={activeSubcategoryId == subcategory.headline}
          />
        ))}
    </div>
  );
};

const Subcategory = ({
  subcategory = undefined,
  active = false,
}: {
  subcategory: any;
  active: boolean;
}) => {
  if (subcategory === undefined) return null;
  return (
    <div style={{ display: active ? "block" : "none" }}>
      <h3 className={classNames(faqSubCategoryHeadline)}>{subcategory.headline}</h3>
      {subcategory.description && (
        <div className={classNames(faqTxtContent)}>
          <HTMLBlock HTML={subcategory.description} />
        </div>
      )}

      {/** dokumenty */}
      {subcategory.items?.length > 0 && <AttachedDocumentsBlock documents={subcategory.items} />}
    </div>
  );
};
