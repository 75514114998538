import React from "react";
import classNames from "classnames";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { createBreadcrumbs } from "@utils/pages";
import { containerMain, containerContent } from "./DefaultLayout.css";

export const DefaultLayout: React.FC<LayoutProps> = ({ page, allPages, children }) => {
  return (
    <>
      <Header />
      <Content>
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerMain)}>
          <div className={classNames(containerContent)}>{children ? children : null}</div>
        </div>
      </Content>
      <CTGlobalFooter />
    </>
  );
};
