import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xbjB3cWp2MCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQouXzFuMHdxanYxIHsKICBjb2xvcjogdmFyKC0tXzE5bXJoYWExayk7Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogdmFyKC0tXzE5bXJoYWExcykgMCB2YXIoLS1fMTltcmhhYTF1KSAwOwogIH0KfQpAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMDI0cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fbedge.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2SQU7DMBBF9znFIDZk4SiuIC3unmugaewmVh07GrtVC%2BLuKK5bSugCFVWwIFKiaPy%2F%2FT3zCu%2Br%2B01bwmsGILXvDe4EaGu0VWxhXL2aZ29ZcVBh1C2dDczrFyWgLGbTB1LdPAOInlbppg0CeDE51KN8iZ02OwEbpDvGnvljRy1imUejCkER8z3W2jbDpiU%2FumtnHI19u%2Bg75k1BAeo1%2BUHdO22DoqG0wHrVkFtbydJWt0%2FxGRZ7lDIeOeu3wKvhU6a%2F6HUkFTFCqdd%2BnIG7GKJDarQVMPiq9JZ7e1DbwKSqHWHQzgqwziq40V3vKKANUUNovd4vozGjMybTfFyp8tOJiNZtFKW5fL3qZ%2B8qP2lo6sI4ztUj83%2BE%2FhRCPCF0TYB%2BnnLya9Scv%2BaF3Jxr55UBuqTt3%2BHuYzR%2FGqB38aR%2BouEGAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebLawsAndRules%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA72Uz3KbMBDG736K7fQSH8QAsZ1YufTU18gItAY16M9IS23a8bt3kEljMG6cmUw5wMxqV%2FvbT59InrO2zjsKKfxeAGjhK2U4pO4AoiULP4W%2FY%2Bw522pfC5GFZQw%2FLY6L5LUyi5VOSKlMFUufFgCNCsQCdQ0y6hxyMNZgvyBVcI3oOOwajJn9l%2B29cBz6dx%2F60QZSu46V1hAaOuWyQMLTqPUqtt5ZQyyoX8ghSx7WHvUJwCCrUVU1cciHYEzdCa2ajk9mWy37hNI21k%2BXurh00oaRdX%2BHHEKFJbJ6WpXZWPYmzAh9fYk%2BS3420T%2Fg1%2F8XfjM2C6SX1ZDCF6Wd9STM%2BNAeYnFhD%2F3gcfPCeomeFXbgOrC9klRzeNwMrIUoXypvWyPZMOTX7%2FEZQc6OMGzuhVRtuJIz70mpPJakrOHg7b4Pi0ZVhilCHTiUaAj9u3YFqHprT%2Fqa5W1HcKbbY9Rt9oyzl3HqdmquPMnn78Wb6ZAIPQtOlFFLliZpfoPzPv%2FamBnnfdMolYBQekQDwki408q82iRL85U7LOPQk3%2FDNRWu6vCOEjdocTyn2F5S3M8j3I%2FgZhg2H4M4%2FgEfEbmQ2wUAAA%3D%3D%22%7D"
export var bedge = 'ss64vh1';
export var bedgeActive = 'ss64vh2';
export var componetHeader = '_1uh2yts8 _1n0wqjv0';
export var componetHeaderH1 = '_1uh2yts9';
export var faqCategory = '_1uh2yts4';
export var faqSelect = '_1uh2yts7';
export var faqSubCategory = '_1uh2yts5';
export var faqTopTags = '_1uh2yts0';
export var faqTopTags_ul = '_1uh2yts1';
export var faqTxtContent = '_1uh2yts6';