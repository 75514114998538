import { useState, useEffect, useRef } from "react";
import Image, { ImageProps, ImageLoader } from "next/image";
import { ASPECT_RATIO, value2CssValue } from "@consts/aspectRatio";
import { TRadiusValue } from "@consts/roundCorners";
import { imageMediaServerUrl, isStaticImportImage } from "@utils/imageLoaders";
import classNames from "classnames";
import {
  smartImageContainer,
  smartImageContainerLoader,
  smartImageContainerLoaderWide,
} from "./SmartImage.css";

type ExtendProps = {
  cropAspectRatio?: ASPECT_RATIO;
  radius?: TRadiusValue;
  bigImage?: boolean;
  full?: boolean;
};

export type SmartImageProps = ImageProps & ExtendProps;

const isSvg = (srcValue: any) => {
  const src = srcValue?.src ? srcValue.src : srcValue;
  const extension = src.split(".").pop() as string;
  return extension != undefined && ["svg", "svgz"].includes(extension.toLowerCase());
};

// pomer stran kontejneru
export const calculateCrosAspectRatioContainer = (
  cropAspectRatio: ASPECT_RATIO | undefined,
  imageWidth: number | undefined,
  imageHeight: number | undefined
) => {
  let cropAspectRatioImage = cropAspectRatio || ASPECT_RATIO.ORIGINAL;
  return cropAspectRatioImage == ASPECT_RATIO.ORIGINAL
    ? `${imageWidth}/${imageHeight}`
    : value2CssValue[cropAspectRatioImage];
};

export const SmartImage = (props: SmartImageProps) => {
  const { cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;

  // svg obrazek
  if (isSvg(imageProps.src)) {
    return <SmartSvgImage {...props} />;
  }

  // bitmapovy obrazek
  return <SmartBitmapImage {...props} />;
};

const SmartSvgImage = (props: ImageProps) => {
  return <Image {...props} alt={props.alt} unoptimized={true} />;
};

const backendLoader: ImageLoader = ({ src, width }) => {
  return imageMediaServerUrl(src as string, width);
};

export const SmartBitmapImage = (props: SmartImageProps) => {
  const { cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageSizes, setImageSizes] = useState(100);

  // nastaveni loaderu pro nestaticky import
  if (!isStaticImportImage(imageProps.src)) {
    imageProps.loader = backendLoader;
  }

  // uprava image props
  const originalImageWidth = imageProps?.width ? (imageProps.width as number) : undefined;
  const originalImageHeight = imageProps?.height ? (imageProps.height as number) : undefined;
  imageProps.width = undefined;
  imageProps.height = undefined;
  imageProps.fill = true;

  // nastaveni sizes obrazku
  const updateImageSizes = () => {
    if (containerRef.current?.clientWidth) {
      setImageSizes(containerRef.current.clientWidth);
    }
  };

  // init sirky obrazku a event listener na resize
  useEffect(() => {
    updateImageSizes();
    window.addEventListener("resize", updateImageSizes);
    return () => {
      window.removeEventListener("resize", updateImageSizes);
    };
  }, []);

  return (
    <span
      ref={containerRef}
      className={smartImageContainer}
      style={{
        aspectRatio: calculateCrosAspectRatioContainer(
          cropAspectRatio,
          originalImageWidth,
          originalImageHeight
        ),
        borderRadius: radius || undefined,
      }}
    >
      <span
        className={classNames(
          smartImageContainerLoader,
          imageSizes > 1300 ? smartImageContainerLoaderWide : null
        )}
      ></span>
      <Image
        {...imageProps}
        alt={!alt ? "" : alt}
        sizes={`${imageSizes}px`}
        style={{
          objectFit: "cover",
          borderRadius: radius || undefined,
        }}
      />
    </span>
  );
};
