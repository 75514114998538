import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FSocialLinksBlock%2FSocialLinksBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VVwW6jMBC95yssrVZqDkaYhqRxLnvqb1QGHGLF2K5xG7Kr%2FPsK24Ah0PSw0iKFiLFn3syb53F0yeSu2cfgzwqAiuiSCZhJY2SFwSfRTxC%2BoX2lT4Sg9%2FVhBUBG8nOp5YcoYC651Bj8eLVPu6hIUTBR3rkq5yp1QTXUpGAf9d0eafcUrFacXDHIuMzPh9VtFbkMkc3wKIWBNftNMUBRqmnV%2BnAmKDxRVp5Ma951drv5SCrGr1O01IL5AsZLV7vkqTBSYRCr5vCQHZd%2BT0A8eNiPvo7E1jGLjM7rkZdq7O8OyCXUR3wOejfG2tiVCyvMCQMUxz%2Fb8IY2BhLOSoEBp0cTlNZ%2BTsu1DMzU2oOkFqTv25FT697%2Bw4smCoP23ZosKGSGVrXbB2tDtAlibcOEJ6B12Bftmv1VXruHsTrJzCz2UV6c7DhtMECBfX8vx2eULChym%2FwXSYoZSfZ9YsIm6Y%2FZA2JFSAkJZ4XfP1ZkNi%2BJUf9zKgzVrbkk8wqb0VCPkE%2Fpj6OX3QL73yE%2FtnicGkM1rBXJHWNRPPR04FE1ACHXgOm4WhTV%2B3qUff1Z%2FhN5DpHG%2FUhdej584uXSRUx8u35VtGAE1LmmVAAiCvBUMQG913YTq2Ztg0fhLbE85t0NcRscNt6hG0Gpz2s8hWzOzjwaRLOXyOw5CCBTDxloR8huAi1qMAiw9QG%2BPBDKV3r7C5DSrjBABwAA%22%7D"
export var socialBlock = 'wbo7x90';
export var socialBlockBtn = 'wbo7x94';
export var socialBlockMultipleLinks = 'wbo7x9b';
export var socialBlockMultipleLinks_link = 'wbo7x9c';
export var socialBlockOneLink = 'wbo7x95';
export var socialBlockOneLinkIcon = 'wbo7x96';
export var socialBlockOneLinkIcon_svg = 'wbo7x97';
export var socialBlockOneLinkTxt = 'wbo7x98';
export var socialBlockOneLinkTxt_h4 = 'wbo7x99';
export var socialBlockOneLinkTxt_mr0 = 'wbo7x9a';
export var socialBlockTxtNoMargin = 'wbo7x93';
export var socialBlock_h3 = 'wbo7x91';
export var socialBlock_p = 'wbo7x92';