import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import Lightbox, { CustomSlide } from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import { isCustomSlide, CustomSlideComponent } from "./CustomSlideComponent";
import "yet-another-react-lightbox/styles.css";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { RADIUS } from "@consts/roundCorners";
import { imageMediaServerUrl, ORIGINAL_IMAGE } from "@utils/imageLoaders";

import {
  gallery,
  lightBoxOverwrite,
  galleryMain,
  galleryItemCount,
  galleryItem,
  galleryGrid_1,
  galleryGrid_2,
  galleryGrid_3,
  galleryGrid_4,
  galleryItemCount_p,
} from "./GalleryBlock.css";

export const GalleryBlock = ({
  items,
  showMasterImage,
}: {
  items: any[];
  showMasterImage: boolean;
}) => {
  const [itemInProjectorIndex, setItemInProjectorIndex] = useState<null | number>(null);

  const showItemInProjector = (index: number) => {
    setItemInProjectorIndex(index);
  };

  const resetItemInProjectorIndex = () => {
    setItemInProjectorIndex(null);
  };

  const getMasterMedia = () => (showMasterImage ? items[0] : undefined);
  const getThumbnails = () => (showMasterImage ? items.slice(1) : items);

  return (
    <>
      {/** master item */}
      {getMasterMedia() ? (
        <MasterItem item={getMasterMedia()} showItemInProjectorCallback={showItemInProjector} />
      ) : null}
      {/** thumbnails */}
      {getThumbnails().length > 0 ? (
        <Thumbnails items={getThumbnails()} showItemInProjectorCallback={showItemInProjector} />
      ) : null}
      {/** lightbox */}
      <LightboxComponent
        items={items}
        showItemIndex={itemInProjectorIndex}
        showMasterImage={showMasterImage}
        resetItemInProjectorIndex={resetItemInProjectorIndex}
      />
    </>
  );
};

const MasterItem = ({
  item,
  showItemInProjectorCallback = undefined,
}: {
  item: any;
  showItemInProjectorCallback?: any;
}) => {
  return (
    <div
      className={classNames(galleryMain)}
      onClick={() => {
        showItemInProjectorCallback && showItemInProjectorCallback(0);
      }}
    >
      {!!item?.video ? (
        <>
          <VideoBlock videoID={item.video} />
        </>
      ) : (
        <>
          <ImageBlock
            image={{
              downloadUrl: item.image.downloadUrl,
              width: 610,
              height: 343,
              title: item.image.title,
            }}
            cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9}
            radius={RADIUS.BASE}
          />
        </>
      )}
    </div>
  );
};

const Thumbnails = ({
  items,
  showItemInProjectorCallback = undefined,
}: {
  items: any[];
  showItemInProjectorCallback?: any;
}) => {
  const [widthCont, setWidthCont] = useState<number>(0);
  const [maxGallery, setMaxGallery] = useState<number>(4);
  const refCont = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (refCont.current) {
        setWidthCont(refCont.current.offsetWidth);
      }
    };

    updateWidth();

    const updateMaxGallery = (width: number) => {
      if (width < 540) setMaxGallery(2);
      else if (width < 768) setMaxGallery(3);
      else setMaxGallery(4);
    };

    updateMaxGallery(widthCont);

    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, [widthCont]);

  const galleryToRender = items.slice(0, maxGallery);

  const galleryGridClass = () => {
    switch (maxGallery) {
      case 1:
        return galleryGrid_1;
      case 2:
        return galleryGrid_2;
      case 3:
        return galleryGrid_3;
      case 4:
        return galleryGrid_4;
      default:
        return "";
    }
  };

  return (
    <div className={classNames(gallery, galleryGridClass())} ref={refCont}>
      {galleryToRender.map((item: any, i: number) => (
        <div
          className={classNames(galleryItem)}
          key={i}
          onClick={() => {
            showItemInProjectorCallback && showItemInProjectorCallback(i + 1);
          }}
        >
          {i === galleryToRender.length - 1 && items.length > maxGallery ? (
            <div className={classNames(galleryItemCount)}>
              <p className={galleryItemCount_p}>+{items.length - maxGallery}</p>
            </div>
          ) : null}
          <ThubnailItem key={i} item={item} />
        </div>
      ))}
    </div>
  );
};

const ThubnailItem = ({ item }: { item: any }) => {
  return (
    <>
      {!!item?.video ? (
        <VideoBlock videoID={item.video} />
      ) : (
        <ImageBlock
          image={{
            downloadUrl: item.image.downloadUrl,
            width: 266,
            height: 149,
            title: item.image.title,
          }}
          cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9}
          radius={RADIUS.BASE}
        />
      )}
    </>
  );
};

// https://yet-another-react-lightbox.com/documentation
const LightboxComponent = ({
  items,
  showItemIndex = null,
  showMasterImage = false,
  resetItemInProjectorIndex = undefined,
}: {
  items: any[];
  showItemIndex?: null | number;
  showMasterImage: boolean;
  resetItemInProjectorIndex?: any;
}) => {
  const [index, setIndex] = useState<number>(0);
  const [show, setShow] = useState<boolean>(true);
  const slides: CustomSlide[] = items.map((item: any) => {
    const downloadUrl = item?.image ? `${imageMediaServerUrl(item.image.downloadUrl, ORIGINAL_IMAGE)}?download` : false;
    return {
      item: item,
      downloadUrl: downloadUrl,
      title: item.title,
      type: "custom-slide",
    };
  });

  useEffect(() => {
    setIndex(showItemIndex != null ? showItemIndex : 0);
    setShow(showItemIndex != null);
  }, [showItemIndex]);

  return (
    <div>
      <Lightbox
        open={show}
        className={classNames(lightBoxOverwrite)}
        close={() => {
          resetItemInProjectorIndex && resetItemInProjectorIndex();
          setShow(false);
        }}
        plugins={[Download]}
        slides={slides}
        index={showMasterImage ? index : index - 1}
        render={{
          slide: ({ slide, rect }) =>
            isCustomSlide(slide) ? (
              <CustomSlideComponent slide={slide as CustomSlide} rect={rect} slides={slides} />
            ) : undefined,
        }}
        carousel={{ preload: 1, padding: 0 }}
        download={{
          download({ slide, saveAs }) {
            saveAs(`${window.location.origin}${slide.downloadUrl}`);
          },
        }}
        styles={{ container: { backgroundColor: "rgba(4,30,66, .9)" } }}
      />
    </div>
  );
};
