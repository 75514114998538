import React, { useRef, useEffect, useState } from "react";
import { Player, Product, PlayerLoaderRef } from "@czechtv/player";
import classNames from "classnames";
import { imageMediaServerUrl, isStaticImportImage, ORIGINAL_IMAGE } from "@utils/imageLoaders";
import { videoWrap } from "@components/blocks/VideoBlock/VideoBlock.css";

type TVideoIdType = "idec" | "versionId";

interface VideoBlockProps {
  videoID: string;
  videoIdType?: TVideoIdType;
  title?: null | string;
  subtitle?: null | string;
  previewImageUrl?: undefined | string;
}

// api pro zaznam informaci o browseru a stranka FAQ
const faqReportApiUrl = process.env.NEXT_PUBLIC_USER_BROWSER_INFO_API;
const createFaqUrl = () => {
  const loc = window.location;
  if (!["www.ceskatelevize.cz", "ceskatelevize.cz"].includes(loc.host)) {
    return `${loc.origin}/vse-o-ct/caste-dotazy/ivysilani/`;
  } else {
    return undefined;
  }
};

// omezeni kvality videa
//const maxAutoQuality = 720;
const maxAutoQuality = undefined;

export const VideoBlock = ({
  videoID,
  videoIdType = "idec",
  //title = null,
  //subtitle = null,
  previewImageUrl = undefined,
}: VideoBlockProps) => {
  const playerRef = useRef<PlayerLoaderRef>(null);
  const [faqUrl, setFaqUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const newFaqUrl = createFaqUrl();
    newFaqUrl && setFaqUrl(newFaqUrl);
  }, []);

  // uprava cesty k nahledovemu obrazku
  const uppdatedPreviewImageUrl = previewImageUrl
    ? isStaticImportImage(previewImageUrl)
      ? previewImageUrl
      : imageMediaServerUrl(previewImageUrl, ORIGINAL_IMAGE)
    : undefined;

  // uprava props
  const updatedProps = {
    product: Product.ctdefault,
    fairplayAccessToken: "vseoct",
    widevineAccessToken: "vseoct",
    dev: false,
    playerRef: playerRef,

    // video s idec
    idec: videoIdType == "idec" ? videoID : undefined,

    // versionId video (pridavane rucne do systemu)
    versionId: videoIdType == "versionId" ? videoID : undefined,

    // playlisty
    usePlayability: true,
    useNewPlaylist: true,
    playlistVodUri: "https://api.ceskatelevize.cz/video/v1/playlist-vod/v1",
    playlistLiveUri: "https://api.ceskatelevize.cz/video/v1/playlist-live/v1",

    // vlastni nahledovy obrazek
    previewImageUrl: uppdatedPreviewImageUrl,
    delayLoadingPlaylist: !!previewImageUrl,

    // omezeni kvality videa
    maxAutoQuality: maxAutoQuality,

    // autoplay
    autoplay: false,

    // faq report
    faqReportApiUrl: faqReportApiUrl,
    faqUrl: faqUrl,
  };

  return (
    <div className={classNames(videoWrap)}>
      <Player {...updatedProps} />
    </div>
  );
};
