import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fbedge.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2SQU7DMBBF9znFIDZk4SiuIC3unmugaewmVh07GrtVC%2BLuKK5bSugCFVWwIFKiaPy%2F%2FT3zCu%2Br%2B01bwmsGILXvDe4EaGu0VWxhXL2aZ29ZcVBh1C2dDczrFyWgLGbTB1LdPAOInlbppg0CeDE51KN8iZ02OwEbpDvGnvljRy1imUejCkER8z3W2jbDpiU%2FumtnHI19u%2Bg75k1BAeo1%2BUHdO22DoqG0wHrVkFtbydJWt0%2FxGRZ7lDIeOeu3wKvhU6a%2F6HUkFTFCqdd%2BnIG7GKJDarQVMPiq9JZ7e1DbwKSqHWHQzgqwziq40V3vKKANUUNovd4vozGjMybTfFyp8tOJiNZtFKW5fL3qZ%2B8qP2lo6sI4ztUj83%2BE%2FhRCPCF0TYB%2BnnLya9Scv%2BaF3Jxr55UBuqTt3%2BHuYzR%2FGqB38aR%2BouEGAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebCtCouncil%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81UwXKbMBC98xXb6SUcxABO7ES%2B5NTf6Ai0BjUgEWldm3b87x3JNAZM4pw65QAzu3pv364em1So602dwu8IoBW2UpoVhsi0HH4Ke8fY9%2ByptbUQ2Wu8jQBK0xh7lXuJt9EpSs5kWSDbGU3MqV%2FIIU%2FyB4uthzdKI6tRVTX5%2BFsYidAy14lS6YoDS5P0DROYdqJVTT8vfP%2B%2Bpj6khpbIdBzS7ri92aUJsE5IGYSko77y0ZACG4g9mTmBi0N4hFsF3IXyLKNRjpijvkFGfYcctNHoE1K5rhE9h12D4aT%2FsoMVHQf%2F9qEfe0dq17PSaEJN57PMkbA0KryeX0SWbJYv4j%2Bb9OZa%2BKLuUT8fSH%2F4l9IfpyaB9BoLKXxRbWcsCT2%2BrqcALczRNx2IC2MlWlaYQdORHZSkmsPjetBZiPKlsmavJRsa%2FPotPBOBi%2FIHciuk2rt3zix7USqLJSmjOVhz8GHRqEozRdg6DiVqQnvTpgCVt%2FSsro4%2FN%2F5T9NyiVAJcaRE1CC3hrlX674SyNL%2FvjnEY6WQvTXy1Gtwz89XqsrCWd9N6lL%2F505wuEtbXEvJJreX1eGNBfkrD6Q8iMcUu6wUAAA%3D%3D%22%7D"
export var bedge = 'ss64vh1';
export var bedgeActive = 'ss64vh2';
export var componentHeader = 'genh7h0';
export var componetHeader_H1 = 'genh7h1';
export var faqCategoryHeadline = 'genh7h6';
export var faqSelect = 'genh7h9';
export var faqSubCategoryHeadline = 'genh7h7';
export var faqTopTags = 'genh7h2';
export var faqTopTagsList = 'genh7h3';
export var faqTxtContent = 'genh7h8';