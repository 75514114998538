import React from "react";
import { WrapperPositions } from "@czechtv/global-components";
import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { createBreadcrumbs } from "@utils/pages";
import { Content } from "@czechtv/components";
import classNames from "classnames";
import { vars as globalVars } from "@czechtvPackagesOverrides/styles/vanilla/global/global.css";

import {
  componetHeaderWithImage,
  componetHeaderWithImageBackgroundWrap,
  componetHeaderWithImageTxt,
  componetHeaderWithImageTxt_h1,
  componetHeaderWithImageTxtAr,
} from "@czechtvPackagesOverrides/styles/vanilla/components/headerWithImage/headerWithImage.css";

const globalMenuConfig = {
  ...projectGlobalMenuConfig,
  defaultPosition: WrapperPositions.absolute,
  style: {
    default: {
      dark: true,
      backgroundColor: "transparent",
    },
    floating: {
      dark: true,
      backgroundColor: globalVars.colors.blueBase,
    },
  },
};

export const DefaultLayout: React.FC<LayoutProps> = ({ page, children, allPages }) => {
  return (
    <>
      <Header globalMenuConfig={globalMenuConfig} />
      <main>
        <header className={classNames(componetHeaderWithImage)}>
          {/** custom obrazek na pozadi hlavicky */}
          <div className={classNames(componetHeaderWithImageBackgroundWrap)}>
            {!!page?.headerImage ? (
              <SmartImage
                src={page.headerImage.downloadUrl}
                alt={page.title}
                width={page.headerImage.width}
                height={page.headerImage.height}
                bigImage={true}
              />
            ) : null}
          </div>
          <Content>
            <div className={classNames(componetHeaderWithImageTxt)}>
              {allPages ? (
                <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} darkMode={true} />
              ) : null}

              {/** title */}
              <h1 className={classNames(componetHeaderWithImageTxt_h1)}>{page.title}</h1>

              {/** perex */}
              {!!page?.perex ? (
                <div className={classNames(componetHeaderWithImageTxtAr)}>
                  <HTMLBlock HTML={page.perex} />
                </div>
              ) : null}

              {/** akcni tlacitko v header */}
              {!!page?.headerActionLink ? (
                <ButtonPrimary
                  anchor
                  href={page.headerActionLink.url}
                  target={page.headerActionLink?.target ? page.headerActionLink?.target : undefined}
                >
                  {page.headerActionLink?.title}
                </ButtonPrimary>
              ) : null}
            </div>
          </Content>
        </header>
        <Content>{children ? children : null}</Content>
      </main>
      <CTGlobalFooter />
    </>
  );
};
