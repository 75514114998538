import dayjs from "dayjs";
import * as timezone from 'dayjs/plugin/timezone'

type TDateTimeFormat = 'DATE'

export const formatDateTime = (value: string, format: TDateTimeFormat = 'DATE') => {
  try {
    const _value = value.split('T')
    const d = _value[0].split('-')
    const t = _value.length > 1 ? _value[1].split(':') : []
    switch (format) {
      case 'DATE':
        return `${parseInt(d[2])}. ${parseInt(d[1])}. ${parseInt(d[0])}`
      default:
        return value
    }
  } catch (_err) {
    //console.log(_err)
    return ''
  }
}

export const DEFAULT_TZ = 'Europe/Prague'

export const dayjsWithTZ = (tz: string) => dayjs().tz(tz)

export const dayjsCzech = () => dayjsWithTZ(DEFAULT_TZ)

// porovnava "naive" date string YYYY-MM-DD
export const compareDateStrings = (d1: string, d2: string) => {
  d1 = d1.split("-").join("");
  d2 = d2.split("-").join("");
  return parseInt(d1) - parseInt(d2);
};
