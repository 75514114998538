import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import _ from "lodash";
import { getSideMenuItems, getRootPage } from "./utils";
import classNames from "classnames";
import { useMinDesktop1024 } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { slugify } from "../../../../utils/slugify";

import {
  container,
  container_ul,
  buttonOpenenerSubmenu,
  buttonOpenenerSubmenuOpen,
  containerMobileOpen,
  itemActive,
  itemExactlyActive,
  itemExactlyActive_link,
  childrensOpen,
  childrensClose,
  toggleBtn,
  toggleBtnOpen,
  toggleBtnIcon,
  toggleBtnIconOpen,
  itemLevel_0,
  itemLevel_0_link,
  itemLevel_1,
  itemLevel_1_link,
  itemLevel_2,
  itemLevel_2_link,
  itemLevel_3,
  itemLevel_3_link,
} from "./SideMenu.css";

export { getSideMenuItems, getRootPage };

export interface ISideMenuItem {
  childrens?: ISideMenuItem[];
  label: string;
  target?: undefined | "_blank";
  url: string;
  hideChildrens?: boolean;
}

export interface SideMenuProps {
  rootPage?: any;
  currentUrl?: undefined | string;
  items: ISideMenuItem[];
}

const findItemByUrl = (items: ISideMenuItem[], url: string): ISideMenuItem | undefined => {
  for (const item of items) {
    if (item.url === url) {
      return item;
    }
    if (item.childrens) {
      const foundInChildren = findItemByUrl(item.childrens, url);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  return undefined;
};

const removeLastSlash = (str: string) => str.replace(/\/$/, "");

const isActive = (item: ISideMenuItem, currentUrl: undefined | string): boolean =>
  !!item?.url && !!currentUrl && currentUrl.startsWith(item.url);

const isExactlyActive = (item: ISideMenuItem, currentUrl: undefined | string) =>
  !!item?.url && !!currentUrl && removeLastSlash(item.url) == removeLastSlash(currentUrl);

const isActiveOrHaveActiveChildrens = (
  item: ISideMenuItem,
  currentUrl: undefined | string
): boolean => !!currentUrl && (isActive(item, currentUrl) || haveActiveChildren(item, currentUrl));

const haveActiveChildren = (item: ISideMenuItem, currentUrl: undefined | string): boolean =>
  !!item?.childrens &&
  item.childrens.filter((childItem) => isActiveOrHaveActiveChildrens(childItem, currentUrl))
    .length > 0;

const createMenuItemKey = (url: string, label: string, level: number = 0) =>
  slugify(`menu-item-${label}-${url}-${level}`);

interface MenuItemProps {
  currentUrl?: string;
  item: ISideMenuItem;
  level?: number;
  closeMenu: () => void; //zavirani menu na mobilu
}

// polozka menu
const MenuItem = ({ item, currentUrl = undefined, level = 0, closeMenu }: MenuItemProps) => {
  const isMinDesktop = useMinDesktop1024();
  const [submenuIsOpen, setSubmenuIsOpen] = useState<boolean>(
    isActiveOrHaveActiveChildrens(item, currentUrl)
  );

  const getLevelClass = () => {
    switch (level) {
      case 0:
        return itemLevel_0;
      case 1:
        return itemLevel_1;
      case 2:
        return itemLevel_2;
      case 3:
        return itemLevel_3;
    }
  };

  const getLevelLinkClass = () => {
    switch (level) {
      case 0:
        return itemLevel_0_link;
      case 1:
        return itemLevel_1_link;
      case 2:
        return itemLevel_2_link;
      case 3:
        return itemLevel_3_link;
    }
  };

  const Button = () => {
    return (
      <button
        type="button"
        className={classNames(
          buttonOpenenerSubmenu,
          submenuIsOpen ? buttonOpenenerSubmenuOpen : undefined
        )}
        onClick={() => {
          setSubmenuIsOpen(!submenuIsOpen);
        }}
      />
    );
  };

  const ChildrenItems = ({ item }: { item: ISideMenuItem }) => {
    if (!item?.childrens) return <></>;
    return (
      <ul className={classNames(container_ul, !!submenuIsOpen ? childrensOpen : childrensClose)}>
        {item.childrens.map((childrenItem) => (
          <MenuItem
            currentUrl={currentUrl}
            item={childrenItem}
            key={createMenuItemKey(childrenItem.url, childrenItem.label, level + 1)}
            level={level + 1}
            closeMenu={closeMenu}
          />
        ))}
      </ul>
    );
  };
  // handle zavirani hlavniho menu na mobilu
  const handleCloseMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    !isMinDesktop ? closeMenu() : null;
  };

  return (
    <li
      className={classNames(
        item,
        isActiveOrHaveActiveChildrens(item, currentUrl) ? itemActive : null,
        isExactlyActive(item, currentUrl) ? itemExactlyActive : null,
        getLevelClass()
      )}
    >
      {/** button pro otevirani submenu */}
      {item?.childrens && !item?.hideChildrens && item?.childrens.length > 0 ? <Button /> : null}

      {/** link */}
      <Link
        href={item.url}
        className={classNames(
          getLevelLinkClass(),
          isExactlyActive(item, currentUrl) ? itemExactlyActive_link : null,
          item?.hideChildrens && isActiveOrHaveActiveChildrens(item, currentUrl)
            ? itemExactlyActive_link
            : null
        )}
        target={item?.target ? item.target : undefined}
        onClick={handleCloseMenu}
      >
        {item.label}
      </Link>

      {/** submenu */}
      {item?.childrens && !item?.hideChildrens ? <ChildrenItems item={item} /> : null}
    </li>
  );
};

// SideMenu
export const SideMenu = ({ items, currentUrl = undefined, rootPage = undefined }: SideMenuProps) => {
  const router = useRouter();
  const isMinDesktop = useMinDesktop1024();
  const [realCurrentUrl, setRealCurrentUrl] = useState<string | undefined>(
    removeLastSlash(currentUrl ? currentUrl : router.asPath)
  );
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<undefined | ISideMenuItem>(undefined);

  const zeroLevelLink : ISideMenuItem[] = [{
    label: rootPage?.title,
    url: rootPage?.path,
  }];

  // nastaveni realCurrentUrl
  useEffect(() => {
    realCurrentUrl != router.asPath && setRealCurrentUrl(removeLastSlash(router.asPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  // nastaveni currentItem
  useEffect(() => {
    if (realCurrentUrl != undefined) {
      let newCurrentItem = findItemByUrl(items, realCurrentUrl);
      newCurrentItem = newCurrentItem !== undefined ? newCurrentItem : findItemByUrl(zeroLevelLink, realCurrentUrl);
      !_.isEqual(newCurrentItem, currentItem) && setCurrentItem(newCurrentItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realCurrentUrl, items]);

  // zavirani hlavniho menu na mobilu
  const closeMenu = () => {
    setMobileOpen(false);
  };

  return (
    <>
      {realCurrentUrl ? (
        <>
          {!isMinDesktop && items.length > 0 ? (
            <>
              <button
                className={classNames(toggleBtn, mobileOpen ? toggleBtnOpen : null)}
                type="button"
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                }}
              >
                {currentItem?.label}
                <svg
                  className={classNames(toggleBtnIcon, mobileOpen ? toggleBtnIconOpen : null)}
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    d="M3.57757 6.07742C3.87798 5.77702 4.35067 5.75391 4.67758 6.0081L4.75609 6.07742L10.0002 11.3208L15.2442 6.07742C15.5446 5.77702 16.0173 5.75391 16.3442 6.0081L16.4228 6.07742C16.7232 6.37782 16.7463 6.85052 16.4921 7.17743L16.4228 7.25593L10.5894 13.0893C10.289 13.3897 9.81632 13.4128 9.48941 13.1586L9.41091 13.0893L3.57757 7.25593C3.25214 6.9305 3.25214 6.40286 3.57757 6.07742Z"
                    fill="#6F6F6F"
                    fillRule="evenodd"
                  />
                </svg>
              </button>

              {mobileOpen ? (
                <div style={{ height: "auto" }}>
                  <div className={classNames(container, containerMobileOpen)}>
                    <ul className={classNames(container_ul)}>
                      {zeroLevelLink
                        .filter((item) => item.url !== undefined)
                        .map((item) => (
                          <MenuItem
                            currentUrl={realCurrentUrl}
                            item={item}
                            key={createMenuItemKey(item.url, item.label)}
                            closeMenu={closeMenu}
                          />
                        ))}
                      {items.map((item) => (
                        <MenuItem
                          currentUrl={realCurrentUrl}
                          item={item}
                          key={createMenuItemKey(item.url, item.label)}
                          closeMenu={closeMenu}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {items.length > 0 ? (
                <div className={classNames(container)}>
                  <ul className={classNames(container_ul)}>
                    {zeroLevelLink
                      .filter((item) => item.url !== undefined)
                      .map((item) => (
                        <MenuItem
                          currentUrl={realCurrentUrl}
                          item={item}
                          key={createMenuItemKey(item.url, item.label)}
                          closeMenu={closeMenu}
                        />
                      ))}
                    {items.map((item) => (
                      <MenuItem
                        currentUrl={realCurrentUrl}
                        item={item}
                        key={createMenuItemKey(item.url, item.label)}
                        closeMenu={closeMenu}
                      />
                    ))}
                  </ul>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : null}
    </>
  );
};
