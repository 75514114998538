import { CtCouncilMeetingPage } from "./PageTypes/CtCouncilMeetingPage/CtCouncilMeetingPage";
import { BaseContentPage } from "./PageTypes/BaseContentPage/BaseContentPage";
import { PersonPage } from "./PageTypes/PersonPage/PersonPage";
import { DocumentsPage } from "./PageTypes/DocumentsPage/DocumentsPage";
import { staticContentBlocks } from "./StaticContentComponents/index";

const sectionId = "webCtCouncil";

const baseSectionPath = "/rada-ct";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "CtCouncilMeetingPage", component: CtCouncilMeetingPage },
  { id: "BaseContentPage", component: BaseContentPage },
  { id: "PersonPage", component: PersonPage },
  { id: "DocumentsPage", component: DocumentsPage },
];

export { staticContentBlocks };

const getSideMenuRootPage = (page: any, pages: any[]): any | undefined => {
  if (!!siteSection.isThisSection(page)) {
    let rootPage = pages.find((p) => p.path == baseSectionPath);
    [baseSectionPath].map((subSectionBasePath) => {
      if (page.path.startsWith(subSectionBasePath)) {
        rootPage = pages.find((p) => p.path == subSectionBasePath);
      }
    });
    return rootPage;
  }
};

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
  getSideMenuRootPage: getSideMenuRootPage,
};
