import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcM67Do4jpu6y6tO0qYd9t0HJpbSmupatNJB%2FpF%2F8SG2oV%2Bvd%2FDnA8Bk8gurZX9T1zrA1XrfN%2B1Vvdp%2B69eT7%2B18v6h7Bdt6tZ1s2767%2FvTCAscWV%2B2y%2B7xezF43obHJl1296JrXDUyAHz8Zvmr6tl29fSk7Mvj%2FfdyI%2FtrOuv3ydd4H6OfT%2BmPlFaDXCsg5BXjxkioGSiv%2BGX8th69sGteYqSJjCkWlMl4Q9TMv6E1GZzoQrlCkvcKKBNIMiHEKSlSAVSGjmQ0QkldYlhmlNhLeclqMAixJOrqOV7YspVGBySRvngIzCohw2MbYTcIoypVGYl3C2MdR2Gaw2wAvD36%2FWYBFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmfXAeK%2BAFFhJbJIXBaffMXEXYyLOrOFru4yjPjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabm9zGAQsHxBZaZlD%2B8Bzo8S9axM3QmEY8DZJXRyssOeIpOjlWjTPCozwhyVD7DYJoWQFZBLj1I6elwntMmOJNKzEjaBGdT87LaeRtzLr1s%2FnrexpxPzXB%2BZpkCYpyMiMeMpG3MxRnJ7zouAcUh6QoFcQkozklTKohLQNOUNk7YaQmoSRCl3OaczVKtKNUqx7Xv9HedauWGMlGuW3GeauVimYocd%2FNOrktvpEi1qjLc7cAVxalOVS55cZ7yJOE6%2BVyt4kg1dKpTbp5gGqn%2BVCeTu%2FsqQLk5jA7XAdCL000AsuL0LoAhcdoHsFqcbgNYqbYL4KTaPoCXavcBCqn2EKCUaocApVR7DFBJtafAk218TDoAotAjDIAkBIn4WCiSCYBGSJINgFZKugDopKQP0OynXTOZtk9d2%2FN%2FTJeo9KURw5YK9qCX29FxmSbPs7%2Bw%2BpIuQG8OgJsD0OYAMqoqb%2BfOdiZrVweQ0U0z7UZNrt1oFqA4pe3vP%2F%2FHMbOGCwAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fbedge.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2SQU7DMBBF9znFIDZk4SiuIC3unmugaewmVh07GrtVC%2BLuKK5bSugCFVWwIFKiaPy%2F%2FT3zCu%2Br%2B01bwmsGILXvDe4EaGu0VWxhXL2aZ29ZcVBh1C2dDczrFyWgLGbTB1LdPAOInlbppg0CeDE51KN8iZ02OwEbpDvGnvljRy1imUejCkER8z3W2jbDpiU%2FumtnHI19u%2Bg75k1BAeo1%2BUHdO22DoqG0wHrVkFtbydJWt0%2FxGRZ7lDIeOeu3wKvhU6a%2F6HUkFTFCqdd%2BnIG7GKJDarQVMPiq9JZ7e1DbwKSqHWHQzgqwziq40V3vKKANUUNovd4vozGjMybTfFyp8tOJiNZtFKW5fL3qZ%2B8qP2lo6sI4ztUj83%2BE%2FhRCPCF0TYB%2BnnLya9Scv%2BaF3Jxr55UBuqTt3%2BHuYzR%2FGqB38aR%2BouEGAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fcore%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71Ty27jMAy8%2BytY7KU5yLCDpA%2F1sqf9jUK2aEetJQoSs4lb5N8XVpw2dtNtT%2FVBBkiOOEMN88eyaLDBtoDXDMCq0BonofB7UFsm%2BKvCtRCP5b0NG6XKuEjhh%2ByQ5SdkmZBeaW1cm6APGUBnIovIfYeCe48SHDkcEtpE36leQtNhqhz%2BYheUlzCcQ%2BhpG9k0vajJMTo%2B1orIKvCk9Sq1bsixiOYFJZT57TqgPRJwKDZo2g1LWI7BVNooa7pezrStFkNBTR2FeapPqeNsBJN%2FEzmGKmImO0eVlGDvg5lQX3%2BkfpH5maL%2FkF%2F%2FLPmbqVmg%2BIiGAq6M9RRYuemj3SZwRftBeLq8oqAxiIpGXnuxM5o3Eu5uRq6Vqp%2FbQFunxSjy15%2F0TUhelDBeHpQ22%2FhJzWVPahOwZkNOQqDdEFadaZ0wjDZKqNExhi%2FtCtAO1p71dYvvPcEh%2B21RGwWxDogOlNNwbY07Tagsliu%2FX6SRztZi4q5lvjzZaL4a%2BVkCmTGI6FWdBiqKvDjDfbk9h%2BzwD5qqFbRRBAAA%22%7D"
export var bedge = 'ss64vh1';
export var bedgeActive = 'ss64vh2';
export var faqCategoryHeadline = '_10fefeg4';
export var faqSelect = '_10fefeg7';
export var faqSubCategoryHeadline = '_10fefeg5';
export var faqTopTags = '_10fefeg0';
export var faqTopTagsList = '_10fefeg1';
export var faqTxtContent = '_10fefeg6';